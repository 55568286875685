// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// ) !default;

// @import "breakpoints";
@import "../components/bootstrap/bootstrap-grid";
// components/bootstrap/bootstrap-grid.scss

body {
	color: #4A4A4A;
}

.color-red {
	color: $red;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

.color-white {
	color: white;
}

.text-align-center {
	text-align: center;
}

h1,.h1,
h2,.h2 {
	font-weight: lighter;
}

.position-relative {
	position: relative;
}

hr {
	border-color: #C1C1C1;
	border-bottom: 0;
}

a {
	&:hover,
	&:active,
	&:focus,
	& {
		color: $red;
	}
}

a i.fa {
	margin-left: .5rem;
}

section.gray {
	background-color: #e9e9e9;
	box-shadow: inset 0 6px 6px 0 rgba(0,0,0,.15);
}

.course-card.gray {
	background: #f6f5f5;
}

.buttons a {
	max-width: 569px;
	margin: 0 0 .75rem 0;
	border-radius: 3px;
	color: white;
	display: block;
	font-weight: bold;
	background-color: $red;
	background-color: #088A97;
	padding: 20px 25px;
	box-shadow: 0 3px 2px 0 rgba(0,0,0,0.30);
	i.fa {
		font-size: 22px;
	}

	&:hover {
		text-decoration: none;
		span {
			text-decoration: underline;
		}
	}
}

.buttons-light a {
	background-color: white;
	border: 1px solid #474747;
	margin: 0;
	color: $lighterred;
	box-shadow: none;
}

.color-green {
	color: #088A97;
}

a.btn {
	font-size: 18px;
	font-weight: bold;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $green;
	color: white;
	padding: 35px 54px;
	box-shadow: 0 4px 2px 0 rgba(0,0,0,0.20);
	border-radius: 3px;
	span {
		hyphens: auto;
	}
	&:hover {
		text-decoration: none;
		span {

		}
	}

	[class*="col-"] & {
		min-height: 100%;
	}

}
a.btn--big {
	i.fa {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translate3d(0,-50%,0);
		font-size: 30px;
//		margin-left: auto;
	}
}



.numeric-navigation {
	margin: 2rem 0 1rem;
	ul {
		list-style: none;
		display: flex;
		justify-content: center;

		a {
			margin: 5px;
			background-color: $lighterred;
			color: white;
			padding: 5px 13px;
			display: block;
		}
		.numeric-navigation__item--pagenumnumeric-navigation__item--active a {
			background-color: $red;
		}
	}
}

h2.bigger-text {
	font-size: 2.2rem;
}

.content a {
	text-decoration: underline;
}

// Hyphenation with fallback
// 'overflow-wrap' is fallback for browsers which don't support hyphenation or can't hyphenate text
@mixin pwd-hyphens() {
	overflow-wrap: break-word;
	hyphens: auto;
}

@mixin link-with-inline-svg {
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	font-weight: bold;

	&:hover {
		text-decoration: none;
	}

	&:focus, &:active {
		outline: 2px dotted;
	}

	svg, .rounded-svg-container {
		min-width: 1.75rem;
		width: 1.75rem;
		height: 1.75rem;
		margin-right: .5rem;
		transition: .2s ease-in-out;
	}

	.rounded-svg-container > svg {
		width: 100%;
		min-width: 100%;
		height: 100%;
		margin: 0;
	}
}

.link-with-inline-svg {
	@include link-with-inline-svg;
}

@mixin link-with-inline-svg-right {
	@include link-with-inline-svg;

	svg, .rounded-svg-container {
		margin-right: initial;
		margin-left: .5rem;
	}
}

.link-with-inline-svg-right {
	@include link-with-inline-svg-right;
}

@mixin rounded-svg-container {
	display: flex;
	align-items: center;
	border-radius: 10rem;
	padding: .25rem;
	background: transparent;
	border: 2px solid $lighterred;

	svg {
		color: $lighterred;
		fill: $lighterred;
	}
}

.rounded-svg-container {
	@include rounded-svg-container;
}

@mixin pwd-figcaption() {
	margin: 1rem 0;
	font-style: italic;
	text-align: center;
	color: #000;
	font-size: 1rem;
}

figcaption {
	@include pwd-figcaption();
}

@mixin pwd-button {
	display: inline-block;
	background-color: $lighterred;
	color: white;
	padding: 10px 20px;
	border-radius: 3px;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.30);
	text-decoration: none;

	&:hover, &:focus, &:active {
		text-decoration: underline;
	}
}
