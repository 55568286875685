.search-mobile {
	display: none;
}

.header__lang-selector {
	margin-top: .75rem;
}

.header__lower {
	justify-content: flex-start;

	.nav-main--secondary {
		display: none;
	}
}

.header__quick-link {
	line-height: 1.3;
	border-right: 1px solid;
	margin-right: 1rem;
	margin-top: px-to-rem(6);
	padding-right: 1rem;
	font-weight: bold;

	.nav-main--secondary .nav-main__list-item--has-children {
		margin-bottom: 0;

		a {
			color: var(--pink);
		}

		.nav-main__sub-button {
			border-color: var(--pink);

			svg {
				color: var(--pink);
			}

			&:hover, &:focus, &:active, &[aria-expanded="true"] {
				background: var(--pink);

				svg {
					color: #fff;
				}
			}
		}
	}
}

/**
 *	"Tab" styles
 */
.nav-main__list {
	padding: 0;

	& > .nav-main__list-item {
		margin: 0;
		padding: 1rem;
		border-radius: 4px 4px 0 0;

		&.active, &.nav-main--active, &.nav-main__list-item--ancestor {
			background: white;
			margin: 0;

			a {
				color: black;
			}

			.nav-main__sub-button {
				color: #000;
			}
		}
	}
}

