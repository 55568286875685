.course-card {
	background-color: white;
	border-radius: 3px;
	border: 1px solid #B4B4B4;
	font-size: 16px;

	// .content {
	// }

	footer a {
		@include pwd-button;
	}
}
