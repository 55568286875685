// == PWD WP Sass Framework ==
// Theme general structure and helper classes

// Common

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Credit: https://github.com/suitcss/base

// [tabindex="-1"]:focus {
// 	outline: 0;
// }

.content-container {
	margin: $base-margin auto;
	max-width: $base-content-width;
	// padding-left: $grid-gutter-width;
	// padding-right: $grid-gutter-width;
}

// Blocks general. each block takes care of its own width
.main-content-area {
	& > * {
		@extend .content-container;

		// For some elements, hide footer top border with minus margin
		// &.acf-image-text, &.acf-featured-article {
		// 	&:last-child {
		// 		margin-bottom: -2px;
		// 	}
		// }
	}

	// For some elements, ensure there is margin before footer
	// & > p, & > ul {
	// 	&:last-child {
	// 		margin-bottom: $base-margin;
	// 	}
	// }
}

// @media screen {
// }

// @include media-breakpoint-up(sm) {
// }

// @media screen and (min-width: map-get($grid-breakpoints, sm)) {
// }

// @include media-breakpoint-up(md) {
// }

// @media screen and (min-width: map-get($grid-breakpoints, md)) {
// }

// @include media-breakpoint-up(lg) {
// }

// @media screen and (min-width: map-get($grid-breakpoints, lg)) {
// }

// @include media-breakpoint-up(xl) {
// }

// @import "../vendor/bootstrap/utilities/display";
// @import "../vendor/bootstrap/utilities/flex";
// @import "../vendor/bootstrap/utilities/spacing";
