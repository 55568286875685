section.blogs {
	background-color: #E9E9E9;
	box-shadow: inset 0 6px 6px 0 rgba(0,0,0,0.15);
}

.blogs__blog_item {
	margin-top: 1rem;
	margin-bottom: 1rem;
	flex: 1;
	background-color: white;
	box-shadow: 0 4px 4px 0 rgba(0,0,0,0.22);

	@include media-breakpoint-up(lg) {
		margin-right: 1rem;
		& + & {
			margin-right: 0;
			margin-left: 1rem;
		}
	}

	time {

	}

	h2.entry-title {
		font-weight: bold;
		color: $red;
		font-size: 22px;
		a {
			color: inherit;
		}
	}

	.image {
		min-width: 138px;
		width: 138px;
		height: 138px;
		overflow: hidden;
		border-radius: 200px;
		border: 1px solid #D8D8D8;
	}

	a.read-more-link {
		position: absolute;
		right: 0;
		bottom: 0;
		background-color: $lighterred;
		color: white;
		text-decoration: none;
		padding: 5px 15px;
		font-size: 14px;
		font-weight: bolder;
	}
}

.news-and-events {
	.container:after {
		position: absolute;
		width: 1px;
		height: 100%;
		left: 50%;
		left: calc(50% - (1px / 2));
		top: 0;
		background-color: #E9E9E9;

		@include media-breakpoint-up(md) {
			content: "";
		}

		@include media-breakpoint-up(lg) {
			left: calc(66.66666666% - (1px / 2));
		}
	}
}

.news-and-events__event_item {
	margin: 1rem 0;
	time {
		margin-right: 1rem;
		font-weight: bold;
		color: $red;
		padding: 1.75rem 1rem;
		background-color: #EDDBE1;
	}
	h2.entry-title {
		color: $text-color;
		font-size: 20px;
		font-weight: bold;
		a {
			color: inherit;
		}
	}
}

.news-and-events__news > article {
	&:last-child {
		margin-bottom: 0;
	}
}

// .news-and-events__news_item {
// 	margin: 1rem 0;
// 	border-left: 4px solid $lighterred;
// 	padding-left: 1rem;
// 	h2.entry-title {
// 		color: $text-color;
// 		font-size: 20px;
// 		font-weight: bold;
// 		a {
// 			color: inherit;
// 		}
// 	}
// }

//Courses

// .course-card {
// 	background-color: white;
// 	border-radius: 3px;
// 	border: 1px solid #B4B4B4;
// 	font-size: 16px;
// 	.content {

// 	}
// 	footer a {
// 		display: inline-block;
// 		background-color: $lighterred;
// 		color: white;
// 		padding: 10px 20px;
// 		border-radius: 3px;
// 		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.30);
// 	}
// }
