header.banner {
	box-shadow: inset 0 1px 30px 0 #7F1C3B;
	background-color: $lighterred;
}

.hero {
	position: relative;
	height: 300px;
	background-color: silver;

	@include media-breakpoint-up(md) {
		height: 447px;
		min-height: 447px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

a.brand {
	display: inline-flex;
	padding: 2rem 1rem;
	margin: 0 auto;
	font-size: 24px;
	background-color: $lighterred;
	color: white;

	svg {
		width: 86px;
		height: 112px;
	}

	&:hover {
		color: white;
	}

	body:not(.home) & {
		@include media-breakpoint-up(md) {
			margin: -2rem 0 1rem;
			padding: 0;
			position: static;
		}
	}

	&.mobile-logo {
		background-color: transparent;
		transform: scale(0.8);
		transform-origin: 0 0;
		margin-bottom: -50px;
	}
}

.banner__top-bar {
	@include media-breakpoint-down(sm) {
		background-color: white;
	}

	.home.page & {
		background-color: white;
	}

	ul.nav {
		font-size: 16px;
		align-items: center;

		li {
			padding: 1rem 0;

			a {
				display: block;
				padding: 3px 13px;
				color: $red;

				body.home & {
					color: $red;
				}
			}

			body:not(.home) &:not(:first-child) {
				background-color: #A02F52;
			}

			body:not(.home) &:last-child {
				padding: 14px 20px 14px 0;
			}

			.search-trigger {
				padding: 0;
				font-size: 24px;
			}
		}
	}

	.search-field {
		padding: 5px 10px;
		font-size: 16px;
		border: 1px solid $red;
		background-color: #f4f4f4;
	}
	.search-form {
		display: flex;
	}

	.search-form button {
		background-color: transparent;
		border: 0;
		color: $red;
		font-size: 22px;
	}
}

.mobile-search-bar {
	.search-form {
		input,
		label {
			width: 100%;
		}
	}
}

.nav-secondary {
	@include media-breakpoint-up(md) {
		background-color: transparent;
	}
	@include media-breakpoint-down(sm) {
		a svg * {
			fill: $red;
		}
	}
}

.nav-secondary-inner {
	padding: 1rem;
	margin-left: -15px;
	margin-right: -15px;
	background-color: white;
	@include media-breakpoint-up(md) {
		border-radius: 0 0 4px 4px;
		padding: 0 10px;
	}
}

.nav-bar {
	position: relative;
	z-index: 1;

	.home & {
		border-top: 2px solid white;
	}
}

.nav-primary ul.nav {
	justify-content: center;

	a {
		font-weight: 600;
		display: block;
		padding: 1rem;
		color: white;
	}

	li.current-page-ancestor a,
	li.current_page_item a {
		border-radius: 4px;
		@include media-breakpoint-up(md) {
			border-radius: 4px 4px 0 0;
		}
		background-color: white;
		color: black;
	}

	li {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: auto;
		}
	}
}
