// == PWD WP Sass Framework ==
// Block styles

// @import "../../../../assets/styles/common/variables";
// @import "../../../../assets/styles/common/mixins";

.acf-text-media {
	border: 1px solid #b6b6b6;

	.text {
		padding: 1rem 1rem 1.5rem;

		a.cta {
			@include pwd-button;
		}
	}

	@include media-breakpoint-up(lg) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: none;
		padding: 0;

		.image, .text {
			width: calc(50% - 1rem);
			padding: 0;
		}
	}
}
