// == PWD WP Sass Framework ==
// Block styles

@use "sass:color";

// @import "../../../../assets/styles/common/variables";
// @import "../../../../assets/styles/common/mixins";

.pwd-infobox {
	position: relative;
	padding: 2rem 2rem;
	background-color: #ececec;

	@include media-breakpoint-up(md) {
		padding: 2rem 3rem;
	}

	h2,h3,h4,h5,h6 {
		font-size: px-to-rem(20);
		font-weight: normal;

		&:first-child {
			margin-top: 0;
		}
	}

	// .wp-block.block-button a {
	// 	@include button-light-red;
	// }

	p:first-of-type {
		margin-top: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}
}
