// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$gray: #474747;
$pink: #FF97B7;
$red: #7F1C3B;
$lighterred: #B13A5F;
$green: #07828D;

$text-color: #4a4a4a;

$font-size-base: 1.125rem; //18px

$font-family-base: 'Open Sans', Arial;
//font-size: $font-size-base;
//font-weight: $font-weight-base;
//line-height: $line-height-base;
//color: $body-color;
//text-align: left; // 3
//background-color: $body-bg; // 2

$base-content-width: 1100px;
$medium-content-width: 1280px;
$wide-content-width: 1380px;
$base-margin: 32px; // 2rem
