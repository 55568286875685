// == PWD WP Sass Framework ==
// Block styles

// @import "../../../../assets/styles/common/variables";
// @import "../../../../assets/styles/common/mixins";

.block-links + link + .block-links,
.block-links + .block-links {
	margin-top: -1rem;
}

.block-links {
	.link-with-inline-svg {
		align-items: flex-start;

		svg {
			color: $lighterred;
			fill: $lighterred;

			&.icon-external-link-01 {
				scale: .8;
			}
		}
	}
}
