// == PWD WP Sass Framework ==
// Block styles

// @import "../../../../assets/styles/common/variables";
// @import "../../../../assets/styles/common/tools";


.acf-social-share {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&__link {
		margin: .25rem;

		& > svg, & > div.rounded-svg-container {
			min-width: 2.5rem;
			width: 2.5rem;
			height: 2.5rem;
			padding: .5rem;
		}
	}
}
