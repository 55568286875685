// == PWD WP Sass Framework ==
// Block styles

// @import "../../../../assets/styles/common/variables";
// @import "../../../../assets/styles/common/mixins";

.acf-contact {
	// Mobile
	img {
		object-fit: contain;
		border-radius: 10rem;
		width: 120px;
		height: 120px;
		margin: 0;
	}

	.wysiwyg {
		padding-top: .5*$base-margin;

		h2,h3,h4,h5,h6,p {
			margin-top: .5rem;
			margin-bottom: .5rem;
		}

		a {
			color: #000;
		}

		h2,h3,h4,h5,h6 {
			font-size: px-to-rem(20);
			font-weight: 500;
		}
	}

	// Desktop
	@include media-breakpoint-up(md) {
		display: flex;
		align-items: center;

		img {
			width: 30%;
			height: 30%;
		}

		.wysiwyg {
			width: 70%;
			padding-top: 0;
			padding-left: $base-margin;

			h2,h3,h4,h5,h6 {
				font-size: px-to-rem(24);
			}
		}
	}
}
