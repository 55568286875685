.content__left_nav {

}

.sub-pages__title {
	display: none;
}

.sub-pages {
	margin-right: 2rem;
	margin-bottom: 2rem;
}

.sub-pages ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.sub-pages__list {
	a {
		display: block;
		padding: 10px;
		border-bottom: 1px solid #B9B9B9;
	}
}

.sub-pages__list ul {
	display: none;
	margin-left: 1rem;
	border-left: 1px solid $lighterred;
}

ul.sub-pages__list > li.current_page_parent,
ul.sub-pages__list > li.current_page_ancestor,
ul.sub-pages__list > li.current_page_item {
	& > ul {
		display: block;
	}
	& > a {
		color: white;
		background-color: $lighterred;
		border-bottom-color: $lighterred;
	}
}

ul.children {
	.current_page_ancestor a,
	.current_page_item a {
		color: $text-color;
		background-color: #E8E8E8;
	}
}



//Breadcrumb
.breadcrumb {
	display: flex;
	overflow: hidden;
	margin: 2rem 0;
	font-size: 14px;
	flex-wrap: wrap;
}

.breadcrumb > span {
	a {
		margin: 0 6px .5rem -3px;
		display: inline-flex;
		align-items: stretch;
		&.current-item {
			color: $text-color;
		}
		&:after {
			background-color: #F1F1F1;
			content: "";
			width: 10px;
			margin-left: -3px;
			display: block;
			transform: skewX(-10deg);
		}

		&:before {
			background-color: #F1F1F1;
			content: "";
			width: 10px;
			margin-right: -3px;
			display: block;
			transform: skewX(-10deg);
		}
		span {
			background-color: #F1F1F1;
			padding: 7px 20px 7px 20px;
		}
	}
	&:last-child a:after,
	&:first-child a:before {
		display: none;
	}
}
