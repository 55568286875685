.content__main {
	flex: 8;

	@include media-breakpoint-up(md) {
		margin-left: 5rem;
	}
}

.content__left_nav {
	flex: 4;
}

.diagnose-groups {
	margin: 2rem 0;
}

.diagnose-group {
	margin-bottom: 2rem;
}

.diagnose-group h2 {
	font-size: 18px;
	font-weight: normal;
	margin: 0 0 1rem 0;
}


//Front page

.notice-box {
	position: relative;
	border: 1px solid $red;
	h2 {
		color: $red;
	}
	a {
		padding: 3px 15px;
		font-size: 14px;
		position: absolute;
		bottom: 0;
		right: 15px;
		color: white;
		background-color: $red;
	}
}

.notice-box__image {
	img {
		object-position: 50% 50%;
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}


.selected-page {
	background-color: white;
	min-height: calc(100% - 1.5rem);
}

.selected-page__image {
	min-width: 200px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
	}
}

.selected-page__content {
	padding: 1rem 2rem;


}


//Contacts

.contacts-link {
	padding: 0 0 1rem;
	border-bottom: 1px solid $red;
	a {
		color: $body-color;
		&:focus,
		&:hover {
			text-decoration: none;
			h3 {
				text-decoration: none;
			}
		}
	}
	h3 {
		color: $red;
		text-decoration: underline;
	}
}


.single-person {
	border: 1px solid $red;
}

.single-person__image {
	@include media-breakpoint-up(md) {
		width: 150px;
	}
}
