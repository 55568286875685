// == PWD WP Sass Framework ==
// Theme header styles
// Template: "templates/header/header.php"

@import "../common/variables";
@import "../common/global";

.header {
	margin: 0 auto;
	background: none;
	border-bottom: 2px solid var(--lila-dark);

	.search-form {
		margin-left: 1rem;
	}
}

// Main navigation

.nav-main__link {
	display: block;
	position: relative;
	line-height: 1.2;
	text-decoration: none;
	font-weight: 600;
	color: var(--lila-dark);
}

.nav-main__list {
	flex-wrap: wrap;
	justify-content: center;
	margin: auto;

	& > .nav-main__list-item > a {
		color: #fff;
	}

	&:not([hidden]) {
		display: flex;
	}
}

.nav-main__list-item {
	margin-bottom: 14px;

	&:not(:last-child) {
		margin-right: $grid-gutter-width * .5;
	}
}

.nav-main__sub-list {
	display: none;
	padding: 0;
	box-shadow: 0 0 1rem rgba(0,0,0, .3);
}


/**
 *	Mobile/Desktop specific styles
 * You can use both, or only one of the options. If only one is used, remove media querys and just import the one you need (and delete unused .scss file)
 * Delete unneeded template part in header.php too if only one is used
 */
@include media-breakpoint-down (md) {
	@import "header-mobile";
}

@include media-breakpoint-up(lg) {
	@import "header-desktop";
}
