
a.cta-block {
    display: flex;
    align-items: center;
    margin: 4rem 0 2rem;
    padding: 1rem;
    border: 1px solid #dedede;
    border-radius: 4px;
    text-decoration: none !important; 
    @media (max-width: 500px) {
        flex-direction: column;
        padding: 1.5rem 1rem 0;
    }
    &:hover, &:focus, &:active {
        img {
            box-shadow: inset 0 4px 0 rgba(white, .5), 0 5px 2px rgba(black, .2);
            transform: translateY(-2px);
        } 
    }


    img {
        width: 170px;
        height: 170px;
        object-fit: contain;
        border: 1px solid #dadada;
        padding: .5rem;
        border-radius: 10rem;
        transition: .2s ease-in-out;
        box-shadow: inset 0 2px 0 rgba(white, .5), 0 3px 2px rgba(black, .2);
        @media (max-width: 991px) {
            height: 120px;
            width: 120px;
        }
    }

    .content {
        padding: 1rem 0 1rem 1rem;
        @media (max-width: 500px) {
            padding: .5rem;
            p { margin-bottom: .75rem }
        }

    }

}

/**
 *  Image only
 */
.cta-img-only {

    margin: 4rem 0;
    text-align: center;

    a {
        &:hover, &:focus, &:active {
            img {
                box-shadow: inset 0 4px 0 rgba(white, .5), 0 5px 2px rgba(black, .2);
                transform: translateY(-2px);
            } 
        }
        img {
            width: 170px;
            height: 170px;
            object-fit: contain;
            border: 1px solid #dadada;
            padding: .5rem;
            border-radius: 10rem;
            transition: .2s ease-in-out;
            box-shadow: inset 0 2px 0 rgba(white, .5), 0 3px 2px rgba(black, .2);
            @media (max-width: 991px) {
                height: 120px;
                width: 120px;
            }
        }

    }
}