// == PWD WP Sass Framework ==
// Block styles

// @import "../../../../assets/styles/common/variables";
// @import "../../../../assets/styles/common/tools";

.acf-attachment {
	margin: 2rem auto 1rem;
	border: 1px solid #b6b6b6;
	padding: 1rem 1rem 0;

	@include media-breakpoint-up(lg) {
		display: flex;
		border: none;
		padding: 0;
	}

	.preview-image {
		box-shadow: 0 .25rem .5rem rgba(0,0,0,.15);
		max-width: 220px;

		@include media-breakpoint-up(lg) {
			width: 30%;
			margin-right: $base-margin;
		}
	}

	.text {
		padding-top: $base-margin;

		@include media-breakpoint-up(lg) {
			width: 70%;
			padding: 0;
		}

		.file-label {
			text-transform: uppercase;
			font-size: 1rem;
		}
	}
}
