.header {
	display: flex;
	justify-content: space-between;
	padding: $base-margin;
}

a.header-logo {
	padding: 2rem 0;
	background: none;

	svg {
		max-width: 50px;
		max-height: 50px;
		margin-bottom: .5rem;
	}
}

.nav-main.nav-mobile-parent .nav-mobile__button {
	border: none;
	border-radius: 0;
	width: 44px;
	min-width: 44px;
	height: 44px;
	padding: .25rem;
	color: #fff;

	&:hover, &:focus, &:active, &[aria-expanded="true"] {
		background: none;
		color: #fff;
	}

	svg {
		height: 100%;
		width: 100%;
		min-width: 100%;
	}
}

.nav-main__list.nav-mobile__menu {
	padding: 0;
	top: calc(100% + 2.125rem);
	box-shadow: 0 0 1rem rgba(0,0,0, .6);
	right: 0;
	width: calc(100vw - 3rem);
	max-width: 350px;
	overflow: hidden;

	.nav-main__list-item, .nav-main__sub-list-item {
		padding: 0;
		border-top: 1px solid;
		border-radius: 0;

		a {
			color: #000;
		}
	}

	.sub-menu.nav-main__sub-list {
		border-top: 0;
		padding-left: 0;
		box-shadow: none;

		&:before {
			display: none;
		}
	}
}

.nav-mobile__menu.nav-mobile__list .nav-main__sub-button {
	color: #000;
}
